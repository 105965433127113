import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import { add_student } from "../services/api";
import { Modal } from "flowbite-react";
import { Spinner } from "flowbite-react";
interface FormValues {
  studentName: string;
  institute: string;
  mobileNo: string;
  parentNo: string;
  registrationNo: string;
  percentage: string;
  month: string;
  year: string;
}

export default function Form() {
  const navigate = useNavigate();
  const initialValues: FormValues = {
    studentName: "",
    institute: "",
    mobileNo: "",
    parentNo: "",
    registrationNo: "",
    percentage: "",
    month: "",
    year: "",
  };
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [formErrors, setFormErrors] = useState<Partial<FormValues>>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues);
    // Clear the specific error for the input being changed
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(await validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = async (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.studentName) {
      errors.studentName = "Student name is required!";
    }
    if (!values.institute) {
      errors.institute = "Institute is required!";
    }
    if (!values.mobileNo) {
      errors.mobileNo = "Mobile number is required!";
    }
    if (!values.parentNo) {
      errors.parentNo = "Parent number is required!";
    }
    if (!values.registrationNo) {
      errors.registrationNo = "Registration number is required!";
    }
    if (!values.percentage) {
      errors.percentage = "Percentage is required!";
    }
    if (!values.month) {
      errors.month = "Month is required!";
    }
    if (!values.year) {
      errors.year = "Year is required!";
    } else {
      setLoading(true);
      let body = {
        name: formValues.studentName,
        institute: formValues.institute,
        mobile_no: formValues.mobileNo,
        parents_no: formValues.parentNo,
        registration_no: formValues.registrationNo,
        percentage: formValues.percentage,
        month: formValues.month,
        year: formValues.year,
      };
      let res = await add_student(body);
      console.log(res.data, "result");
      if (res.data.status) {
        setLoading(false);
        setOpenModal(true);
      } else {
      }
    }
    return errors;
  };

  const handleBack = () => {
    console.log('click');
    navigate("/home");
  };
  const handleGoBack = () => {
    navigate("/home");
  };
  const handleAddStudent = () => {
    setOpenModal(false);
    setFormValues(initialValues); // Reset form values to initial state

    navigate("/form");
  };
  
  return (
    <div className="bg-[#FFC688] h-screen  lg:h-full   lg:p-12">
    <div> 
    <button
        className="btn btn-circle lg:absolute  ml-2 lg:ml-0 bg-[#386FAC] hover:bg-[#5a7ca4] text-white left-4 top-1 cursor-pointer mt-3"
        onClick={handleBack}
      >
        <IoArrowBackSharp size={"30px"} />
      </button>
    </div>
     
      <div className="flex items-center w-full relative h-14 ">
        <h1 className="text-xl lg:text-4xl border-4 p-4  rounded-lg  bg-[#F39F44] text-white font-semibold mt-[8%] lg:mt-[0%]  mx-auto">
          STUDENT  INFORMATION  DATA
        </h1>
      </div>

      <div className="card p-5 lg:p-0 m-1 mt-[10%]   lg:mt-[2%] shadow-2xl bg-[#F86960] border-2 lg:mx-60 rounded-2xl mx-30 ">
        <form
          onSubmit={handleSubmit}
          className="font-normal text-sm text-white lg:text-sm lg:p-8"
        >
          <div className="mb-1">
            <label className="block mb-2 font-medium dark:text-white">
              Student Name
            </label>
            <input
              type="text"
              className="border-2  border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
              placeholder="Enter student's name"
              name="studentName"
              value={formValues.studentName}
              onChange={handleChange}
              style={{ border: "2px solid white" }}
            />

            {formErrors.studentName ? (
              <span className="text-sm text-red-600  bg-white mt-7 pl-2 pr-2">
                {formErrors.studentName}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="mb-1">
            <label className="block mb-2 font-medium dark:text-white">
              Institute Name
            </label>
            <input
              type="text"
              className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
              placeholder="Enter institute name"
              name="institute"
              value={formValues.institute}
              onChange={handleChange}
              style={{ border: "2px solid white" }}
            />
            {formErrors.institute ? (
              <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                {formErrors.institute}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="grid lg:grid-cols-2 lg:gap-6 grid-cols-2 gap-3">
            <div className="mb-1">
              <label className="block mb-2 font-medium dark:text-white">
                Mobile No.
              </label>
              <input
                type="tel"
                maxLength={10}
                className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                placeholder="Enter mobile number"
                name="mobileNo"
                value={formValues.mobileNo}
                onChange={handleChange}
                style={{ border: "2px solid white" }}
              />
              {formErrors.mobileNo ? (
                <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                  {formErrors.mobileNo}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="mb-1">
              <label className="block mb-2 font-medium dark:text-white">
                Parent's Mobile No.
              </label>
              <input
                type="tel"
                maxLength={10}
                className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                placeholder="Enter parent's mobile number"
                name="parentNo"
                value={formValues.parentNo}
                onChange={handleChange}
                style={{ border: "2px solid white" }}
              />
              {formErrors.parentNo ? (
                <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                  {formErrors.parentNo}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="grid lg:grid-cols-2 lg:gap-6 grid-cols-2 gap-3">
            <div className="mb-1">
              <label className="block mb-2 font-medium dark:text-white">
                Registration Number
              </label>
              <input
                type="text"
                className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                placeholder="Enter registration number"
                name="registrationNo"
                value={formValues.registrationNo}
                onChange={handleChange}
                style={{ border: "2px solid white" }}
              />
              {formErrors.registrationNo ? (
                <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                  {formErrors.registrationNo}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="mb-1">
              <label className="block mb-2 font-medium dark:text-white">
                Percentage
              </label>
              <input
                type="text"
                className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                placeholder="Enter percentage"
                name="percentage"
                value={formValues.percentage}
                onChange={handleChange}
                style={{ border: "2px solid white" }}
              />
              {formErrors.percentage ? (
                <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                  {formErrors.percentage}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="grid lg:grid-cols-2 lg:gap-6 grid-cols-2 gap-3">
            <div className="mb-1">
              <label className="block mb-2 font-medium dark:text-white">
                Month
              </label>
              <input
                type="text"
                className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                placeholder="Enter month"
                name="month"
                value={formValues.month}
                onChange={handleChange}
                style={{ border: "2px solid white" }}
              />
              {formErrors.month ? (
                <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                  {formErrors.month}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="mb-1">
              <label className="block mb-2 font-medium dark:text-white">
                Year
              </label>
              <input
                type="text"
                className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-sm rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                placeholder="Enter year"
                name="year"
                value={formValues.year}
                onChange={handleChange}
                style={{ border: "2px solid white" }}
              />
              {formErrors.year ? (
                <span className="text-sm text-red-600  bg-white mt-2 pl-2 pr-2">
                  {formErrors.year}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="text-center mt-3">
            <button
              disabled={loading}
              type="submit"
              className="text-center font-normal text-white bg-[#386FAC] hover:bg-gray-700 rounded-lg text-2xl w-auto sm:w-auto px-9 py-3 border-2 border-white"
            >
              {loading ? (
                <>
                  <Spinner size="md" />
                  <span className="pl-3">Loading...</span>
                </>
              ) : (
                "submit"
              )}
            </button>
          </div>
        </form>
      </div>
      <Modal show={openModal} size="md" popup>
        <Modal.Body className="bg-[#2CC0CF]">
          <div className="text-center">
            <h3 className="mb-5 text-2xl text-black  font-semibold dark:text-gray-400 mt-10">
              Student Added Successfully!
            </h3>
            <div className="flex justify-center gap-4 mt-7">
              <button
                className=" btn btn-outline "
                onClick={() => handleGoBack()}
              >
                Go Back
              </button>
              <button
                className="  btn btn-active hover:bg-gray-800 bg-[#386FAC] text-white border-2 border-white " 
                onClick={() => handleAddStudent()}
              >
                Add Student
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
