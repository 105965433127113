import axios from "axios";

const baseUrl: string = "https://studentinfo-api.aicis.in/api/";

//const baseUrl: string = "http://localhost:2242/api/";


// admin
export const loginAdmin = (data: any) => {
  return axios.post(baseUrl + "admin/login_admin", data);
};

// students
export const add_student = (data: any) => {
  return axios.post(baseUrl + "student/add_student", data);
};

export const get_students = async (currentPage: any, perPage: any) => {
  const response = await axios.get(baseUrl + "student/get_students", {
    params: {
      page: currentPage,
      perPage: perPage,
    },
  });
  return response;
};
export const update_student = (data: any) => {
  return axios.post(baseUrl + "student/update_student", data);
};
export const delete_student = (data: any) => {
  return axios.post(baseUrl + "student/delete_student", data);
};
export const search_student = (data: any) => {
  return axios.post(baseUrl + "student/search_student", data);
};

// number
export const add_number = (data: any) => {
  return axios.post(baseUrl + "mobile_numbers/add_number", data);
};



export const get_numbers = async (currentPage: any, perPage: any) => {
  const response = await axios.get(baseUrl + "mobile_numbers/get_numbers", {
    params: {
      page: currentPage,
      perPage: perPage,
    },
  });
  return response;
};

export const delete_number = (data: any) => {
  return axios.post(baseUrl + "mobile_numbers/delete_number", data);
};
