import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import ReactPaginate from "react-paginate";
import { add_number, delete_number, get_numbers } from "../services/api";
import { Button, Modal, Spinner } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FaPrint } from "react-icons/fa6";
import { IoArrowBackSharp } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";

interface NumberType {
  id: number;
  mobile: string;
}
export default function AddMobileNo() {
  const [loading, setLoading] = useState(false);
  const [allNumbers, setAllNumbers] = useState<NumberType[]>([]);
  const [mobileNo, setMobileNo] = useState("");

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(100); // Number of numbers per page
  const [totalNumbers, setTotalNumbers] = useState(0);

  const [numberId, setNumberId] = useState(); // database id set

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobileNo(e.target.value);
  };

  const handleSubmit = async () => {
    console.log(mobileNo);

    if (!mobileNo) {
      alert("Please enter mobile number");
    } else {
      setLoading(true);
      let body = {
        mobile: mobileNo,
      };
      let res = await add_number(body);
      console.log("res", res.data);
      if (res.data.status === true) {
        setLoading(false);
        alert("Number submitted successfully");
        setMobileNo("");
        fetchNumbers();
      } else {
        alert("something went wrong!");
      }
    }
  };

  const handlePageClick = (data: any) => {
    console.log("final", data);
    setCurrentPage(data.selected + 1);
  };

  const fetchNumbers = async () => {
    let res = await get_numbers(currentPage, perPage);
    if (res.data.data) {
      console.log("res", res.data.data); // Check structure here
      setAllNumbers(res.data.data);
      setTotalNumbers(res.data.totalNumbers);
    }
  };

  const handleDelete = async (id: any) => {
    setOpenDeleteModal(true);
    setNumberId(id);
    console.log(numberId);
  };

  const deleteNumber = async () => {
    setLoading(true);
    let body = {
      id: numberId,
    };
    let res = await delete_number(body);
    console.log(res);
    if (res.data.status) {
      console.log("number deleted ");
      setOpenDeleteModal(false);
      fetchNumbers();
      setLoading(false);
    } else {
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchNumbers();
    setLoading(false);
    console.log("all", allNumbers);
  }, [currentPage]);

  const handlePrintPDF = () => {
    console.log(allNumbers);
    const doc = new jsPDF();
    const title = "Numbers";
    const padding = 10;
    const titleWidth = doc.getTextWidth(title);
    const center = doc.internal.pageSize.width / 2 - titleWidth / 2;
    doc.setTextColor("black");
    doc.text(title, center, padding);

    const maxNumbersPerPage = 100;
    const totalPages = Math.ceil(allNumbers.length / maxNumbersPerPage);

    for (let page = 0; page < totalPages; page++) {
      if (page > 0) {
        doc.addPage();
      }

      const start = page * maxNumbersPerPage;
      const end = Math.min(start + maxNumbersPerPage, allNumbers.length);
      const pageNumbers = allNumbers.slice(start, end);

      const numRows = Math.ceil(pageNumbers.length / 4);
      const body = Array.from({ length: numRows }, (_, rowIndex) => {
        const startIndex = rowIndex * 4;
        const numbers = pageNumbers.slice(startIndex, startIndex + 4);

        return [
          numbers[0] ? `${start + startIndex + 1}` : "", // Index for Column 1
          numbers[0]?.mobile || "", // Mobile No. for Column 1
          numbers[1] ? `${start + startIndex + 2}` : "", // Index for Column 2
          numbers[1]?.mobile || "", // Mobile No. for Column 2
          numbers[2] ? `${start + startIndex + 3}` : "", // Index for Column 3
          numbers[2]?.mobile || "", // Mobile No. for Column 3
          numbers[3] ? `${start + startIndex + 4}` : "", // Index for Column 4
          numbers[3]?.mobile || "", // Mobile No. for Column 4
        ];
      });

      autoTable(doc, {
        head: [
          [
            "No.",
            "Mobile No.",
            "No.",
            "Mobile No.",
            "No.",
            "Mobile No.",
            "No.",
            "Mobile No.",
          ],
        ],
        body: body,
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 30 },
          2: { cellWidth: 10 },
          3: { cellWidth: 30 },
          4: { cellWidth: 10 },
          5: { cellWidth: 30 },
          6: { cellWidth: 10 },
          7: { cellWidth: 30 },
        },
      });
    }

    doc.save("student-numbers-page" + currentPage + ".pdf");
  };
  const handleBack = () => {
    console.log("click");
    navigate("/home");
  };
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3  lg:gap-2 gap-0   w-full bg-[#2CC0CF]">
        <div className="">
          <button
            className="btn btn-circle lg:absolute  ml-2 lg:ml-0 bg-[#386FAC] hover:bg-[#5a7ca4] text-white left-4 top-1 cursor-pointer mt-3"
            onClick={handleBack}
          >
            <IoArrowBackSharp size={"30px"} />
          </button>
        </div>
        <div className="w-[100%] lg:w-[100%] ">
          <div className="flex w-full p-4 lg:gap-3 gap-2 items-center justify-between lg:justify-normal">
            <input
              type="tel"
              maxLength={10}
              value={mobileNo}
              placeholder="Enter Mobile Number"
              onChange={handleChange}
              className="rounded-xl h-14 w-full lg:w-[15000%] bg-[#F86960] text-white placeholder:text-white text-xl focus:outline-none focus:ring-0"
              style={{ border: "2px solid white" }}
            />
            <button
              onClick={handleSubmit}
              className="btn text-center font-normal text-white bg-[#386FAC] border-2 border-white hover:bg-gray-700 rounded-lg text-xl px-5 py-1"
            >
              {loading ? (
                <>
                  <Spinner size="md" />
                  <span className="pl-3">Loading...</span>
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>

        <div className="w-[100%] flex justify-end gap-3 items-center mt-3  pr-3 pb-3">
          <button
            className="btn text-xl hover:bg-[#F39F44] bg-[#F39F44] text-white border-2 border-white px-5 py-1 rounded-lg"
            onClick={() => handlePrintPDF()}
          >
            <FaPrint />
            Print
          </button>
        </div>
      </div>

      <div className="flex flex-col min-h-screen bg-[#F39F44] text-white">
        <div className="flex-grow">
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-0 text-black font-semibold p-3">
            {allNumbers.map((number: any) => (
              <div
                key={number.id}
                className="relative p-2 text-2xl text-center border-2 border-white hover:bg-black hover:text-white hover:cursor-pointer group"
              >
                {number.mobile}
                <button
                  onClick={() => handleDelete(number.id)}
                  className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded w-[96%] hidden group-hover:block"
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
          <div className="pagination-container">
            {allNumbers.length ? (
              <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={Math.ceil(totalNumbers / perPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination justify-content-center p-4 mt-4"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* Delete Modal code */}
      <Modal show={openDeleteModal} size="md" popup>
        <div
          className="w-full bg-[#2CC0CF]"
          style={{
            borderTop: "2px solid white",
            borderLeft: "2px solid white",
            borderRight: "2px solid white",
          }}
        >
          <button
            onClick={() => setOpenDeleteModal(false)}
            className="btn btn-square btn-outline float-end mt-3 mr-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <Modal.Body
          className="bg-[#2CC0CF] pt-3 lg:pt-0"
          style={{
            borderBottom: "2px solid white",
            borderLeft: "2px solid white",
            borderRight: "2px solid white",
          }}
        >
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-700 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-black dark:text-gray-400">
              Are you sure you want to delete this number?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => deleteNumber()}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpenDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
