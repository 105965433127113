import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../services/api";
import { Spinner } from "flowbite-react";
export default function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (e: any) => {
    setPassword(e.target.value);
  };

  const submit = async () => {

    if (!password) {
    
      setAlertMessage("Please enter password");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } else {
      setLoading(true);
      let body = {
        password: password,
      };

      let res = await loginAdmin(body);
      console.log(res);
      if (!res.data.status) {
        setLoading(false);
        setAlertMessage("Wrong password!");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      } else {
        navigate("/home");
        setLoading(false);
      }
    }
  };

  return (
    <div className="bg-[#FFC688] h-screen w-screen text-center relative">
      <div className="pt-[20%] lg:pt-[10%]">
        <img
          src="aicis.png"
          style={{
            height: "250px",
            width: "250px",
            objectFit: "cover",
            margin: "auto",
          }}
          alt=""
        />
      </div>
      <div className="pl-3 pr-3">
        <input
          type="password"
          placeholder="Enter Your Password"
          onChange={handleChange}
          className="rounded-xl h-14 w-full lg:w-96 mt-5 bg-[rgb(248,105,96)] text-white placeholder:text-white text-xl focus:outline-none focus:ring-0"
          style={{ border: "2px solid white" }}
        />
        <div className="text-center mt-7">
          <button
           disabled={loading}
            onClick={submit}
            className="text-center font-normal text-white bg-[#386FAC] border-2 border-white hover:bg-gray-700 rounded-lg text-xl w-auto sm:w-auto px-5 py-2.5"
          >
           {loading ? (
                <>
                  <Spinner size="md" />
                  <span className="pl-3">Loading...</span>
                </>
              ) : (
                "submit"
              )}
          </button>
        </div>
      </div>
      {showAlert ? (
        <div className="fixed inset-x-0 bottom-0 flex items-center justify-center mb-7">
          <div
            role="alert"
            className="alert alert-error w-full mx-2 lg:mx-80 h-13 p-2 flex items-center justify-center"
          >
            <p className="text-center text-white">{alertMessage}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
