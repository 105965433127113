import { Table } from "flowbite-react";
import { Button, Popover, Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FaPrint } from "react-icons/fa6";
import { IoMdAddCircleOutline } from "react-icons/io";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  delete_student,
  get_students,
  search_student,
  update_student,
} from "../services/api";

export default function Home() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(20); // Number of students per page
  const [totalStudents, setTotalStudents] = useState(0);

  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState([]);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [name, setName] = useState();
  const [studentId, setStudentId] = useState(); // database id set
  const [institute, setInstitute] = useState();
  const [mobile, setMobile] = useState();
  const [parentNo, setParentNo] = useState();
  const [registrationNo, setRegistrationNo] = useState();
  const [percentage, setPercentage] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const location = useLocation();
  const data = location.state;
  const doc = new jsPDF();

  const fetchStudents = async () => {
    let res = await get_students(currentPage, perPage);
    // console.log(res);
    if (res.data.data) {
      setStudents(res.data.data);
      setTotalStudents(res.data.totalStudents);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchStudents();
    setLoading(false);
    //console.log(students);
  }, [currentPage]);

  const handleView = (student: any) => {
    setOpenViewModal(true);
    console.log(student);
    setName(student.name);

    setInstitute(student.institute);
    setMobile(student.mobile_no);
    setParentNo(student.parents_no);
    setRegistrationNo(student.registration_no);
    setPercentage(student.percentage);
    setMonth(student.month);
    setYear(student.year);
  };

  const handleEdit = (student: any) => {
    setOpenEditModal(true);
    setName(student.name);
    setInstitute(student.institute);
    setMobile(student.mobile_no);
    setParentNo(student.parents_no);
    setRegistrationNo(student.registration_no);
    setPercentage(student.percentage);
    setMonth(student.month);
    setYear(student.year);
    setStudentId(student.id);
    console.log(student);
  };

  const handleDelete = (studentId: any) => {
    setOpenDeleteModal(true);
    setStudentId(studentId);
  };
  const deleteStudent = async () => {
    setLoading(true);
    let body = {
      id: studentId,
    };
    let res = await delete_student(body);
    console.log(res);
    if (res.data.status) {
      console.log("student deleted ");
      setOpenDeleteModal(false);
      fetchStudents();
      setLoading(false);
    } else {
    }
  };
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleInstituteChange = (event: any) => {
    setInstitute(event.target.value);
  };

  const handleMobileChange = (event: any) => {
    setMobile(event.target.value);
  };

  const handleParentNoChange = (event: any) => {
    setParentNo(event.target.value);
  };

  const handleRegistrationNoChange = (event: any) => {
    setRegistrationNo(event.target.value);
  };

  const handlePercentageChange = (event: any) => {
    setPercentage(event.target.value);
  };

  const handleMonthChange = (event: any) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setYear(event.target.value);
  };

  const addStudent = () => {
    navigate("/form");
  };

  const handlePageClick = (data: any) => {
    console.log("final", data);
    setCurrentPage(data.selected + 1);
  };
  const updateStudent = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    let body = {
      id: studentId,
      name: name,
      institute: institute,
      mobile_no: mobile,
      parents_no: parentNo,
      registration_no: registrationNo,
      percentage: percentage,
      month: month,
      year: year,
    };
    console.log(body);
    let res = await update_student(body);
    console.log("res is", res);
    if (res.data.status) {
      console.log("updated");
      setOpenEditModal(false);
      fetchStudents();
      setLoading(false);
    } else {
    }
  };
  const handleSearch = async (e: any) => {
    const { value } = e.target;
    if (value.length === 0) {
      setSearchTerm(value);
      fetchStudents();
    } else {
      setSearchTerm(value);
    }
  };
  const handleSearchSubmit = async () => {
    setLoading(true);
    let body = { searchTerm: searchTerm };

    let res = await search_student(body);
    console.log(res.data.data);
    setStudents(res.data.data);
    setLoading(false);
  };

  const handlePrintPDF = () => {
    const doc = new jsPDF();
    const title = "Students information";
    const padding = 10;
    const titleWidth = doc.getTextWidth(title);
    const center = doc.internal.pageSize.width / 2 - titleWidth / 2;
    doc.setTextColor("black");
    doc.text(title, center, padding);

    autoTable(doc, {
      head: [
        [
          " No.",
          "Student's name",
          "Organization name",
          "Student's Mo. No.",
          "Parent's Mo. No.",
          "Reg No.",
          "per",
          " Month",
          "year",
        ],
      ],
      body: students.map((student: any, i) => [
        i + 1,
        student.name,
        student.institute,
        student.mobile_no,
        student.parents_no,
        student.registration_no,
        student.percentage,
        student.month,
        student.year,
      ]),
      columnStyles: {
        0: { cellWidth: 9 },
        1: { cellWidth: 35 },
        2: { cellWidth: 18 },
        3: { cellWidth: 25 },
        4: { cellWidth: 25 },
        5: { cellWidth: 20 },
        6: { cellWidth: 15 },
        7: { cellWidth: 15 },
        8: { cellWidth: 15 },
      },
      // headStyles:{
      //   fillColor:"white",
      //   textColor:'black'
      // }
    });
    doc.save("students -data-page"+currentPage+".pdf");
  };
  const handleAddMobileNumber = () => {
    navigate('/addmobileno')
  };
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2  lg:gap-2 gap-0   w-full bg-[#2CC0CF]">
        <div className="w-[100%] lg:w-[100%] ">
          <div className="flex lg:gap-2 gap-2 items-center  w-full p-1  ">
            <div>
              <img
                alt=""
                src="aicis.png"
                style={{ height: "80px", width: "110px", objectFit: "cover" }}
              />
            </div>

            <div className="w-full lg:w-[100%]  flex gap-5 ">
              <form className="lg:w-[100%] w-[100%]">
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only ">
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-white dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full p-3 ps-10 text-sx text-white   placeholder:text-white
                    rounded-xl border-2 border-white bg-[#F86960] focus:outline-none focus:ring-0"
                    placeholder="Search "
                    onChange={handleSearch}
                    value={searchTerm}
                    style={{ border: "2px solid white" }}
                  />
                </div>
              </form>
            </div>
            <button
              className="btn  text-lg hover:bg-gray-800 bg-[#386FAC] text-white border-2 border-white"
              onClick={() => handleSearchSubmit()}
            >
              search
            </button>
          </div>
        </div>

        <div className="w-[100%] flex justify-end gap-3 items-center mt-3  pr-3 pb-3">
          <button
            className="btn  text-lg hover:bg-[#F39F44] bg-[#F39F44] text-white border-2 border-white"
            onClick={() => handlePrintPDF()}
          >
            <FaPrint />
            Print
          </button>
          <button
            className="btn  text-lg hover:bg-[#671c71] bg-[#8944b4] text-white border-2 border-white"
            onClick={() => handleAddMobileNumber()}
          >
            <IoMdAddCircleOutline size={20} />
            Add Number
          </button>
        </div>
      </div>

      <div className="spinner bg-[#FFAFBC] h-screen w-full ">
        {!loading && !students.length ? (
          <div className="text-4xl text-center pt-9">
            <h1>No data available!</h1>
          </div>
        ) : (
          <div className="overflow-x-auto lg:pl-2 lg:pr-2 pt-3 bg-[#FFAFBC] pb-[5%] ">
            {loading ? (
              <div className="text-center pt-4">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}

            <Table hoverable striped>
              <Table.Head
                className="text-white  "
                style={{ borderWidth: "1px", fontSize: "17px" }}
              >
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  No.
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Student's name
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Organization name
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Student's Mo. No.
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Parent's Mo. No.
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "0.5px" }}
                >
                  Reg No.
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Per
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Month
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Year
                </Table.HeadCell>
                <Table.HeadCell
                  className="bg-[#386FAC]"
                  style={{ borderWidth: "1px" }}
                >
                  Act
                </Table.HeadCell>
              </Table.Head>
              {students.map((student: any, index) => (
                <Table.Body
                  className="divide-y"
                  key={student.id}
                  style={{ borderWidth: "1px", fontSize: "18px" }}
                >
                  <Table.Row>
                    <Table.Cell
                      className=" bg-[#F39F44]  text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {(currentPage - 1) * perPage + index + 1}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F86960]  text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.name}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F39F44] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.institute}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F86960] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.mobile_no}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F39F44] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.parents_no}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F86960] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.registration_no}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F39F44] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.percentage}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F86960] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.month}
                    </Table.Cell>
                    <Table.Cell
                      className=" bg-[#F39F44] text-white"
                      style={{ borderWidth: "1px" }}
                    >
                      {student.year}
                    </Table.Cell>

                    <Table.Cell
                      className=" bg-[#F86960] text-white "
                      style={{ borderWidth: "1px" }}
                    >
                      <Popover
                        placement="left"
                        content={
                          <div className=" flex flex-col gap-3 w-20 p-1 bg-white shadow-2xl">
                            <button
                              onClick={() => handleView(student)}
                              className="btn btn-sm bg-purple-600 hover:bg-purple-500 text-white"
                            >
                              View
                            </button>
                            <button
                              onClick={() => handleEdit(student)}
                              className="btn btn-sm bg-cyan-600 hover:bg-cyan-500 text-white"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(student.id)}
                              className="btn btn-sm bg-red-600 hover:bg-red-500 text-white"
                            >
                              Delete
                            </button>
                          </div>
                        }
                      >
                        <button className="btn btn-circle  border-none bg-transparent hover:bg-transparent ">
                          <BsThreeDotsVertical className="h-5 w-5 text-white" />
                        </button>
                      </Popover>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
            </Table>
          </div>
        )}

        {/* Delete Modal code */}
        <Modal show={openDeleteModal} size="md" popup>
          <div
            className="w-full bg-[#2CC0CF]"
            style={{
              borderTop: "2px solid white",
              borderLeft: "2px solid white",
              borderRight: "2px solid white",
            }}
          >
            <button
              onClick={() => setOpenDeleteModal(false)}
              className="btn  btn-square btn-outline float-end mt-3 mr-3 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <Modal.Body
            className="bg-[#2CC0CF]"
            style={{
              borderBottom: "2px solid white",
              borderLeft: "2px solid white",
              borderRight: "2px solid white",
            }}
          >
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-700 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-black dark:text-gray-400">
                Are you sure you want to delete this student?
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={() => deleteStudent()}>
                  {"Yes, I'm sure"}
                </Button>
                <Button color="gray" onClick={() => setOpenDeleteModal(false)}>
                  No, cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* View modal code */}
        <Modal show={openViewModal} size="2xl" popup>
          <div
            className="w-full bg-[#2CC0CF] "
            style={{
              borderTop: "2px solid white",
              borderLeft: "2px solid white",
              borderRight: "2px solid white",
            }}
          >
            <button
              onClick={() => setOpenViewModal(false)}
              className="btn  btn-square btn-outline float-end mt-3 mr-3 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <Modal.Body
            className="bg-[#2CC0CF] pt-3 lg:pt-0"
            style={{
              borderBottom: "2px solid white",
              borderLeft: "2px solid white",
              borderRight: "2px solid white",
            }}
          >
            <div className="card p-2 lg:p-5 text-left lg:mt-3 shadow-2xl gap-2 bg-[#f7bc7e]  lg:mx-auto  rounded-2xl mx-0  text-black">
              <div className="flex flex-row bg-[#5336b9] text-white rounded-2xl">
                <p className="font-semibold  p-2 text-xl rounded-xl w-[40%] lg:w-[24%]">
                  Name:
                </p>
                <p className=" p-2 text-xl rounded-xl">{name}</p>
              </div>
              <div className="flex flex-row bg-[#1a8549] text-white rounded-2xl">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%] ">
                  Institute:
                </p>
                <p className="p-2 text-xl rounded-xl">{institute}</p>
              </div>
              <div className="flex flex-row bg-[#2571be] rounded-xl text-white">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%]">
                  Mobile No:
                </p>
                <p className="p-2 text-xl rounded-xl">{mobile}</p>
              </div>
              <div className="flex flex-row bg-[#08977b] rounded-xl text-white">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%]">
                  Parent's No:
                </p>
                <p className="p-2 text-xl rounded-xl">{parentNo}</p>
              </div>
              <div className="flex flex-row bg-[#a94f88] rounded-xl text-white">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%]">
                  Reg No:
                </p>
                <p className="p-2 text-xl rounded-xl">{registrationNo}</p>
              </div>
              <div className="flex flex-row bg-[#d74611] rounded-xl text-white">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%]">
                  Percentage:
                </p>
                <p className="p-2 text-xl rounded-xl">{percentage}</p>
              </div>
              <div className="flex flex-row bg-[#c23737f2] rounded-xl text-white">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%]">
                  Month:
                </p>
                <p className="p-2 text-xl rounded-xl">{month}</p>
              </div>
              <div className="flex flex-row bg-[#0a6d68] rounded-xl text-white">
                <p className="font-semibold  p-2 text-xl rounded-xl  w-[40%] lg:w-[24%]">
                  year:
                </p>
                <p className="p-2 text-xl rounded-xl">{year}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Edit modal code */}
        <Modal show={openEditModal} size="4xl" popup>
          <div
            className="  flex justify-between w-full bg-[#2CC0CF]"
            style={{
              borderTop: "2px solid white",
              borderLeft: "2px solid white",
              borderRight: "2px solid white",
            }}
          >
            <h1 className=" lg:ml-[300px] ml-8 text-xl lg:text-2xl mt-3 pt-3 lg:pt-2 font-semibold">
              Edit Student's Information
            </h1>
            <div className="float-end justify-end">
              <button
                onClick={() => setOpenEditModal(false)}
                className="btn  btn-square btn-outline  mt-3 mr-3 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <Modal.Body
            className="bg-[#2CC0CF]"
            style={{
              borderBottom: "2px solid white",
              borderLeft: "2px solid white",
              borderRight: "2px solid white",
            }}
          >
            <div className="card p-2 mt-2 shadow-2xl bg-[#F86960] border-2 lg:mx-1  rounded-2xl mx-0 ">
              <form
                onSubmit={updateStudent}
                className="font-normal text-sm text-white lg:text-sm lg:p-2"
              >
                <div className="mb-2">
                  <label className="block mb-2 font-medium dark:text-white">
                    Student Name
                  </label>
                  <input
                    type="text"
                    className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                    placeholder="Enter student's name"
                    value={name}
                    onChange={(event) => handleNameChange(event)}
                    required
                    style={{ border: "2px solid white" }}
                  />
                </div>
                <div className="mb-2">
                  <label className="block mb-2 font-medium dark:text-white">
                    Institute Name
                  </label>
                  <input
                    type="text"
                    className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                    placeholder="Enter institute name"
                    value={institute}
                    onChange={(event) => handleInstituteChange(event)}
                    required
                    style={{ border: "2px solid white" }}
                  />
                </div>
                <div className="grid lg:grid-cols-2 lg:gap-6 grid-cols-2 gap-3">
                  <div className="mb-2">
                    <label className="block mb-2 font-medium dark:text-white">
                      Mobile No.
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                      placeholder="Enter mobile number"
                      value={mobile}
                      onChange={(event) => handleMobileChange(event)}
                      required
                      style={{ border: "2px solid white" }}
                    />
                  </div>

                  <div className="mb-2">
                    <label className="block mb-2 font-medium dark:text-white">
                      Parent's Mobile No.
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                      placeholder="Enter parent's mobile number"
                      value={parentNo}
                      onChange={(event) => handleParentNoChange(event)}
                      required
                      style={{ border: "2px solid white" }}
                    />
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 lg:gap-6 grid-cols-2 gap-3">
                  <div className="mb-2">
                    <label className="block mb-2 font-medium dark:text-white">
                      Registration Number
                    </label>
                    <input
                      type="text"
                      className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                      placeholder="Enter registration number"
                      value={registrationNo}
                      onChange={(event) => handleRegistrationNoChange(event)}
                      required
                      style={{ border: "2px solid white" }}
                    />
                  </div>

                  <div className="mb-2">
                    <label className="block mb-2 font-medium dark:text-white">
                      Percentage
                    </label>
                    <input
                      type="text"
                      className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                      placeholder="Enter percentage"
                      value={percentage}
                      onChange={(event) => handlePercentageChange(event)}
                      required
                      style={{ border: "2px solid white" }}
                    />
                  </div>
                </div>

                <div className="grid lg:grid-cols-2 lg:gap-6 grid-cols-2 gap-3">
                  <div className="mb-5">
                    <label className="block mb-2 font-medium dark:text-white">
                      Month
                    </label>
                    <input
                      type="text"
                      className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                      placeholder="Enter month"
                      value={month}
                      onChange={(event) => handleMonthChange(event)}
                      required
                      style={{ border: "2px solid white" }}
                    />
                  </div>
                  <div className="mb-5">
                    <label className="block mb-2 font-medium dark:text-white">
                      Year
                    </label>
                    <input
                      type="text"
                      className="border-2 border-[#ffffff] placeholder:text-[#ffffff60] text-lg rounded-lg block w-full p-2.5 outline-none bg-transparent focus:outline-none focus:ring-0"
                      placeholder="Enter year"
                      value={year}
                      onChange={(event) => handleYearChange(event)}
                      required
                      style={{ border: "2px solid white" }}
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button
                    type="submit"
                    className="text-center font-normal text-white bg-[#386FAC] border-2 border-white hover:bg-gray-700 rounded-lg text-xl w-full sm:w-auto px-5 py-2.5"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* react pagination */}
      <div className="pagination-container">
        {students.length ? (
          <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={Math.ceil(totalStudents / perPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination justify-content-center p-4 mt-4"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        ) : (
          ""
        )}

        <div
          className="lg:mb-10"
          style={{
            position: "fixed",
            bottom: 0,
            right: "0",
            marginRight: "16px",
            marginBottom: "5px",
          }}
        >
          <button
            onClick={addStudent}
            className="btn btn-circle bg-[#386FAC] border border-white text-white hover:bg-gray-800  h-13 w-13 "
          >
            <IoMdAdd size="40px" />
          </button>
        </div>
      </div>
    </>
  );
}
